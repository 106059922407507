<template>
  <div class="c-app flex-row align-items-center bg-yellow">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol lg="12" class="font260 text-center" style="margin-top: 20px">
          <strong>Beaming School</strong></CCol
        >
        <CCol
          lg="12"
          class="text-center text-white"
          style="margin-bottom: 20px"
        >
          <strong>Big Data Provider For School.</strong></CCol
        >
        <CCol
          lg="6"
          class="text-center card-yellow"
          style="padding: 20px"
          @click="$router.push({ path: './loginStudent' })"
        >
          <i class="ri-user-5-line font260"></i><br /><strong
            >ฉันคือนักเรียน/ ผู้ปกครอง</strong
          >
        </CCol>
        <CCol
          lg="6"
          class="text-center bg-black text-yellow"
          style="padding: 20px"
          @click="$router.push({ path: './loginTeacher' })"
        >
          <i class="ri-user-line font260"></i><br /><strong
            >ฉันคือ ครู/ เจ้าหน้าที่</strong
          >
        </CCol>
      </CRow>
    </CContainer>
    <!--- MODAL AREA-->
    <CModal
      :show.sync="darkModal"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="dark"
    >
      password หรือ username ไม่ถูกต้อง
      <template #header>
        <h6 class="modal-title">คำเตือน!!</h6>
        <CButtonClose @click="darkModal = false" class="text-white" />
      </template>
      <template #footer>
        <CButton @click="darkModal = false" color="danger">Discard</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      darkModal: false,
    };
  },
  methods: {},
};
</script>
